import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import useStyles from './image.css';

const Image = (props) => {

    const classes = useStyles(props);

    return props.fluid ? (
        <Img className={classes.root} fluid={props.fluid} alt={props.alt} />
    ) : (
        <img className={classes.root} src={props.src} alt={props.alt} />
    )
}

Image.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    fluid: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fit: PropTypes.oneOf(['fill', 'contain', 'cover', 'scale-down', 'none']),
    scale: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
    }),
    useScale: PropTypes.bool,
}

Image.defaultProps = {
    scale: {},
    useScale: true,
}

export default Image;