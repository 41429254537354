import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: props => {

        const height = props.height || theme.components.image.default.height;
        const width = props.width || theme.components.image.default.width;
        const scale = {
            ...theme.components.image.scale,
            ...props.scale,
        }

        function forMedia(query, size) {
            return {
                [theme.breakpoints[query](size)]: {
                    '&, & img': {
                        height: typeof height === 'number' ? height * scale[size] : height,
                        width: typeof width === 'number' ? width * scale[size] : width,
                    }
                }
            };
        }

        const baseRules = props.useScale
            ? {}
            : { height, width }

        const mediaQueries = props.useScale
            ? {
                ...forMedia('only', 'xs'),
                ...forMedia('only', 'sm'),
                ...forMedia('only', 'md'),
                ...forMedia('only', 'lg'),
                ...forMedia('up', 'xl'),
            }
            : {};

        return {
            '&, & img': {
                objectFit: (props.fit || 'unset') + '!important',
                ...baseRules,
            },
            ...mediaQueries,
        }
    },
}))