import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import BlockImage from 'components/image/block';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { getNodeFromQuery } from 'components/utility/data';

const DesignFilmFestivalPage = (props) => {

    const images = props.data.allImages;
    const containerSize = 'md';

    const renderImage = (sourceName, height) => (
        <BlockImage height={height} src={getNodeFromQuery(images, sourceName).publicURL} />
    )

    return (
        <Layout navAttachSize={containerSize}>
            <Box bgcolor="common.white" pb={4}>
                <Container maxWidth={containerSize}>
                    <PageTitle navOffset>
                        {renderImage('animation')}
                    </PageTitle>

                    <Box my={6}>
                        <Typography component="h1" variant="h5">
                            <strong>SFS X Countdown to Earth Week Film Festival</strong>
                        </Typography>
                        <p>
                            The University of Wisconsin-La Crosse (UW-L) Students for Sustainability (SFS) hosts an annual sustainable film festival called <em>"Countdown to Earth Week Film Festival"</em>. I had the pleasure to design the full advertisement spread including the logo, poster, Facebook media, animated video, and email templates.
                        </p>
                    </Box>
                </Container>
            </Box>

            <Box py={16}>
                <Container maxWidth={containerSize}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box m={-4}>
                                {renderImage('desktop', 400)}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box p={2} height="100%" display="flex" flexDirection="column" justifyContent="center">
                                <Typography component="h3" variant="h6">
                                    <strong>Facebook Marketing</strong>
                                </Typography>
                                <p>
                                    Custom Facebook event cover photo and profile picture.
                                </p>
                            </Box>
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={12} sm={6}>
                                <Box m={-4}>
                                    {renderImage('phones', 400)}
                                </Box>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={6}>
                            <Box p={2} height="100%" display="flex" flexDirection="column" justifyContent="center">
                                <Typography component="h3" variant="h6">
                                    <strong>Mail Chimp</strong>
                                </Typography>
                                <p>
                                    Using Mail Chimp to design effective interactive email campaigns targeting
                                    students and staff for upcoming film screenings.
                                </p>
                            </Box>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item xs={12} sm={6}>
                                <Box m={-4} overflow="hidden">
                                    <div style={{ transform: 'scale(1.2)' }}>
                                        {renderImage('phones', 400)}
                                    </div>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </Box>

            <Box bgcolor="common.white" py={16}>
                <Container maxWidth="md" px="8vw">
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {renderImage('big_poster_mockup')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box
                                width="100%"
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                bgcolor="background.default"
                                p={6}
                            >
                                <Typography component="h3" variant="subtitle1">
                                    <strong>Print Campaign</strong>
                                </Typography>
                                <p>
                                    Posters of various sizes were displayed on and off campus.
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

        </Layout>
    );
}

export const query = graphql`
    query {
        allImages: allFile(
            filter: {relativeDirectory: { eq: "design/film_festival" }},
            sort: {fields: name, order: ASC}
        ) {
            edges {
                node {
                    id
                    name
                    publicURL
                    childImageSharp {
                        resolutions {
                            aspectRatio
                        }
                        fluid(maxHeight: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default DesignFilmFestivalPage;