import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Image from './image';

const BlockImage = (props) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="flex-start">
            <Image
                fluid={props.fluid}
                src={props.src}
                alt={props.alt}
                height={props.height || "auto"}
                width="100%"
                fit="contain"
                useScale={props.useScale}
            />
        </Box>
    );
}

BlockImage.propTypes = {
    fluid: PropTypes.object,
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    useScale: PropTypes.bool,
}

BlockImage.defaultProps = {
    useScale: false,
}

export default BlockImage;